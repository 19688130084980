<template>
  <div class="px-0 container-fluid h-100 text-left">
    <div class="container" style="padding-top: 30px">
      <BreadCrumbComponent :pageTitles="['News Blogs', 'News Content']" />
    </div>
    <section>
      <article class="mt-4">
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                "
              >
                <strong>News Content</strong><br />
              </div>
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <span>{{ title }}</span
                ><br />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section v-loading="isBusy">
      <article
        v-if="loadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px"
      >
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div class="pt-2" style="font-weight: 400; font-size: 0.9em">
            Failed to fetch news contents. Please try again
          </div>
          <button
            type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getOneWebsiteBlog"
            style="
              width: 220px;
              text-transform: capitalize;
              height: 45px;
              border-radius: 4px;
              background-color: red;
              font-weight: 500;
            "
          >
            <span style="position: relative; bottom: 1px"
              ><i class="pr-2 fas fa-refresh"></i> Try Again</span
            >
          </button>
        </div>
      </article>

      <article v-show="loading && !loadingError" class="">
        <div class="container">
          <div class="">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave mb-3"
              style="height: 200px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article>

      <article v-if="!loading && !loadingError">
        <div class="container">
          <div class="">
            <div
              v-if="newsContents.length === 0"
              class="w-100 h-100 text-center"
            >
              <p>This blog has no content yet, Add some content</p>
            </div>

            <div
              class="position-relative pl-2"
              v-for="section in newsContents"
              :key="section.id"
            >
              <div class="row w-100">
                <div
                  v-if="section.imagePathUrl"
                  class="col-12 border p-2 mb-2 px-0"
                >
                  <div class="col-md-6 h-auto px-0">
                    <img
                      :src="section.imagePathUrl"
                      class="img-fluid"
                      style="width: 100%; height: auto; object-fit: cover"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="border h-auto rounded p-2 pb-4 col-12 mb-2"
                  v-html="section.htmlContentUrl"
                ></div>
              </div>
              <div class="action-btn">
                <el-button
                  type="warning"
                  icon="el-icon-edit"
                  circle
                  size="mini"
                  @click="editSection(section.id)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="deleteSection(section.id)"
                ></el-button>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-end mb-3 mt-3">
              <el-dropdown trigger="click" @command="handleCommand">
                <el-button type="primary" class="" style="width: 8rem">
                  Add<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="add-txt"
                    >Add Text</el-dropdown-item
                  >
                  <el-dropdown-item command="add-img"
                    >Add Image</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="">
              <el-button type="primary" class="" @click="onSave">
                Save Changes
              </el-button>
            </div>
          </div>
        </div>
      </article>
    </section>

    <AddBlogImageDialog
      :is-visible="addImageDialog"
      :websiteBlogId="$route.params.websiteBlogId"
      @close-dialog="closeAddImageDialog"
      @add-image="addNewsSection"
    />

    <EditBlogImageDialog
      :is-visible="editImageDialog"
      :image-to-edit="sectionToEdit"
      :websiteBlogId="$route.params.websiteBlogId"
      @close-dialog="closeEditImageDialog"
      @edit-image="editImage"
    />

    <AddBlogTextDialog
      :is-visible="addTextDialog"
      :websiteBlogId="$route.params.websiteBlogId"
      @close-dialog="closeAddTextDialog"
      @add-text="addNewsSection"
    />

    <EditBlogTextDialog
      :is-visible="editTextDialog"
      :text-to-edit="sectionToEdit"
      :websiteBlogId="$route.params.websiteBlogId"
      @close-dialog="closeEditTextDialog"
      @edit-text="editText"
    />
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddBlogTextDialog from "@/components/dialogs/add-blog-text-dialog.vue";
import AddBlogImageDialog from "@/components/dialogs/add-blog-image-dialog.vue";
import EditBlogImageDialog from "@/components/dialogs/edit-blog-image-dialog.vue";
import EditBlogTextDialog from "@/components/dialogs/edit-blog-text-dialog.vue";

export default {
  components: {
    BreadCrumbComponent,
    AddBlogTextDialog,
    AddBlogImageDialog,
    EditBlogImageDialog,
    EditBlogTextDialog,
  },

  data() {
    return {
      title: "",
      loading: true,
      loadingError: false,
      isBusy: false,
      websiteContent: {},
      newsContents: [],
      sectionToEdit: {},
      addTextDialog: false,
      addImageDialog: false,
      editImageDialog: false,
      editTextDialog: false,
    };
  },

  computed: {
    isWebsiteContentEmpty() {
      return Object.keys(this.websiteContent).length === 0;
    },
  },

  mounted() {
    this.getOneWebsiteBlog();
    //this.fetchHtmlContent();
  },

  methods: {
    addNewsSection(newItem) {
      this.newsContents.push(newItem);
      console.log(this.newsContents);
    },

    async getOneWebsiteBlog() {
      let request = await this.httpRequest({
        method: "GET",
        url: `website/blogs/fetch-one/${this.$route.params.websiteBlogId}`,
        loadingPropertyName: "loading",
        showSuccessMessage: false,
      });

      if (
        request &&
        request.success &&
        request.message == "Blog fetched successfully"
      ) {
        this.title = request.data.title;
        this.newsContents = request.data.website_content?.content ?? [];
        this.websiteContent = request.data.website_content;
        if (this.newsContents.length > 0) {
          await this.fetchHtmlContent();
        }
      }
    },

    async fetchHtmlContent() {
      try {
        this.loading = true;
        for (let i = 0; i < this.newsContents.length; i++) {
          const item = this.newsContents[i];
          if ("htmlContentUrl" in item) {
            let response = await fetch(item.htmlContentUrl);
            if (response.ok) {
              const htmlContent = await response.text();
              item.htmlContentUrl = htmlContent;
            } else {
              throw new Error(`${response.status}`);
            }
          }
        }
      } catch (error) {
        this.showFailedMessage("Unexpected error", "Unable to load content");
        this.$router.go(-1);
      } finally {
        this.loading = false;
      }
    },

    editSection(sectionId) {
      this.sectionToEdit = this.newsContents.find(
        (item) => item.id === sectionId
      );
      if ("imagePathUrl" in this.sectionToEdit) {
        this.editImageDialog = true;
      } else {
        this.editTextDialog = true;
      }
    },

    editImage(imagePathUrl) {
      const index = this.newsContents.findIndex(
        (item) => item.id === this.sectionToEdit.id
      );
      this.newsContents[index].imagePathUrl = imagePathUrl;
    },

    editText(htmlContentUrl) {
      const index = this.newsContents.findIndex(
        (item) => item.id === this.sectionToEdit.id
      );
      this.newsContents[index].htmlContentUrl = htmlContentUrl;
    },

    async deleteSection(sectionId) {
      await this.$confirm(
        "This will permanently delete this section. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      this.newsContents = this.newsContents.filter(
        (item) => item.id !== sectionId
      );
    },

    closeAddTextDialog() {
      this.addTextDialog = false;
    },

    closeAddImageDialog() {
      this.addImageDialog = false;
    },

    closeEditImageDialog() {
      this.editImageDialog = false;
    },

    closeEditTextDialog() {
      this.editTextDialog = false;
    },

    handleCommand(command) {
      if (command === "add-txt") {
        this.addTextDialog = true;
      }

      if (command === "add-img") {
        this.addImageDialog = true;
      }
    },

    async requestHtmlContentUploadUrl(count) {
      const request = await this.httpRequest({
        method: "POST",
        url: "website/request-for-html-content-upload-url",
        loadingPropertyName: null,
        showSuccessMessage: false,
        body: {
          reason: "COURSE_DETAILS_HTML",
          quantity: count,
        },
      });
      if (!request) return;
      if (request.success && request.message == "UPLOAD URL GENERATED") {
        return request.upload_url;
      }
    },

    async uploadToAWS(uploadUrls, blogTexts) {
      try {
        for (var index = 0; index < uploadUrls.length; index++) {
          let url = uploadUrls[index].url;
          let fields = uploadUrls[index].fields;
          const formData = new FormData();
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", blogTexts[index].htmlContentUrl);
          this.submitting = true;
          let request = await this.$http.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: " ",
            },
          });

          if (request.status == 204) {
            const htmlContentUploadPathUrl = request.headers.location;
            const blogTextId = blogTexts[index].id;
            const updatedBlogText = this.newsContents.find(
              (item) => item.id === blogTextId
            );
            updatedBlogText.htmlContentUrl = htmlContentUploadPathUrl;
            // TODO replace url with text on that item
            // this.onAWSResourceUploaded(request.headers.location);
          } else throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Text now, Please try again"
        );
      }
    },

    validateInput() {
      if (this.newsContents.length === 0) {
        return this.showWarningMessage(
          "Blog is Empty",
          "Please provide some content for this blog"
        );
      }
      return true;
    },

    async onSave() {
      if (this.validateInput() !== true) {
        return;
      }
      let fullScreenLoader;
      try {
        this.loading = true;
        fullScreenLoader = this.$loading({
          lock: true,
          text: "Uploading content...",
          spinner: "el-icon-loading",
          background: "rgba(0%, 0%, 0%, 0.7)",
        });
        const textSections = this.newsContents.filter(
          (item) => "htmlContentUrl" in item
        );
        if (textSections.length > 0) {
          const uploadUrls = await this.requestHtmlContentUploadUrl(
            textSections.length
          );
          await this.uploadToAWS(uploadUrls, textSections);
        }

        let request = await this.httpRequest({
          method: "PATCH",
          url: `website/blogs/save-content/${this.$route.params.websiteBlogId}`,
          loadingPropertyName: "loading",
          showSuccessMessage: true,
          body: {
            items: this.newsContents,
          },
        });

        if (
          request &&
          request.success &&
          request.message == "News Content saved successfully"
        ) {
          this.getOneWebsiteBlog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        fullScreenLoader.close();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.action-btn {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
}
</style>
