<template>
    <el-dialog :visible="isVisible" title="Edit Image " @close="closeDialog" @open="onOpen" width="35%">
        <div v-loading="loading">
            <div class="mb-3">
                <div v-if="blogImage" class="position-relative blog-image-overlay">
                    <img :src="blogImage" style="width: 100%; height: 100%; object-fit: cover;">
                    <div class="remove-img w-100 d-flex align-items-center justify-content-center"
                        style="height: 100%;">
                        <el-button class="remove-img-btn" icon="el-icon-delete"
                            style="font-size: 1.5rem; color: #fff; background-color: rgba(0, 0, 0, 0.45)"
                            @click="handleRemove">
                        </el-button>
                    </div>
                </div>

                <div v-else>
                    <input id="blog-image" type="file" class="d-none" accept=".jpg,.png,.jpeg" @change="onImageChange"
                        @click="onImageInputClick" />
                    <label for="blog-image" class="add-img">
                        <div class="w-100 d-flex align-items-center justify-content-center" style="height: 200px;">
                            <span class="el-icon-plus"></span>
                        </div>
                    </label>
                </div>
            </div>
            <div class="">
                <el-button type="primary" class="w-100" @click="onEdit">Edit</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
        imageToEdit: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            loading: false,
            selectedFile: null,
            blogImage: "",
        };
    },

    methods: {
        closeDialog() {
            this.$emit("close-dialog");
        },

        onOpen() {
            this.blogImage = this.imageToEdit.imagePathUrl;
        },

        validateInput() {
            if (this.selectedFile === null && this.blogImage === "") {
                return this.showWarningMessage("Image required", "Please provide an image file");
            }
            const maxFileSizeMB = 2;
            if (this.selectedFile.size > maxFileSizeMB * 1024 * 1024) {
                return this.showWarningMessage(
                    "File too large",
                    `File size should not exceed ${maxFileSizeMB}MB`
                );
            }
            return true;
        },

        // Do this to make sure change event for the input fires even after choosing same file
        onImageInputClick(e) {
            e.target.value = "";
        },

        onImageChange(e) {
            e.preventDefault();
            const ALLOWED_FILE_EXTENSIONS = ["png", "jpg", "jpeg"];

            // Ensure the file type chosen is an image in the allowed file extensions
            this.selectedFile = e.target.files[0];
            const fileExtension = this.selectedFile.name
                ?.split(".")
                ?.pop()
                ?.toLowerCase();
            if (!ALLOWED_FILE_EXTENSIONS.includes(fileExtension)) {
                return this.showWarningMessage(
                    "Invalid file-Type",
                    `Only images of type ${ALLOWED_FILE_EXTENSIONS.toString()} are allowed`
                );
            }
            // Otherwise set the image url to show on the UI
            this.blogImage = URL.createObjectURL(this.selectedFile);
        },

        handleRemove() {
            this.blogImage = "";
            this.selectedFile = null;
        },

        async requestUploadUrl() {
            const request = await this.httpRequest({
                method: "POST",
                url: "website/request-for-file-upload-url",
                loadingPropertyName: "loading",
                showSuccessMessage: false,
                body: {
                    file_name: this.selectedFile.name,
                    file_size: this.selectedFile.size,
                    reason: "NEWS_BLOG",
                },
            });
            if (!request) return;
            if (
                request.success &&
                request.message == "UPLOAD URL GENERATED SUCCESSFULLY"
            ) {
                return request.upload_url;
            }
        },

        async uploadPhotoToAWS() {
            this.isUploadingFile = true;
            try {
                const awsUploadUrl = await this.requestUploadUrl()

                const formData = new FormData();
                Object.entries(awsUploadUrl.fields).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                formData.append("file", this.selectedFile);
                this.isUploadingFile = true;

                let request = await this.$http.post(`${awsUploadUrl.url}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: " ",
                    },
                });

                if (request.status == 204) {
                    return request.headers.location;
                } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
                if (error.message == "Network Error") {
                    this.isUploadingFile = false;
                    return this.showFailedMessage(
                        "Connection failed",
                        "A network error occurred, please try again."
                    );
                }
                this.isUploadingFile = false;
                return this.showFailedMessage(
                    // "Upload Failed",
                    // "Unable to Upload Photo now, Please try again"
                    error
                );
            }
        },

        async onEdit() {
            if (this.validateInput() !== true) {
                return;
            }
            let fullScreenLoader;
            try {
                fullScreenLoader = this.$loading({
                    lock: true,
                    text: "Uploading Image...",
                    spinner: "el-icon-loading",
                    background: "rgba(0%, 0%, 0%, 0.7)",
                });


                const imagePathUrl = await this.uploadPhotoToAWS();

                this.$emit("edit-image", imagePathUrl);
                this.closeDialog()

            } catch (error) {
                console.log(error);
            } finally {
                fullScreenLoader.close();
            }
        },
    }
}

</script>

<style scoped>
* {
    box-sizing: border-box;
}

.remove-img {
    display: none;
}

.remove-img-btn {
    display: none;
}

.blog-image-overlay:hover .remove-img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(0, 0, 0, 0.45);
}

.blog-image-overlay:hover .remove-img-btn {
    display: block;
}

.add-img {
    border: 1px dashed rgb(189, 187, 187);
    border-radius: 5px;
    height: 80%;
    font-size: 3em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>