<template>
    <el-dialog :visible="isVisible" title="Edit Text" @close="closeDialog" width="80%" top="5vh" @open="onOpen">
        <div v-loading="loading">
            <div class="mb-3">
                <ReUsableTextEditor v-model="blogText" placeholderText="Please type here" style="min-height: 70vh"></ReUsableTextEditor>
            </div>
            <div class="d-flex justify-content-end">
                <el-button type="primary" class="" @click="onEdit">Edit</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import ReUsableTextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
export default {
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
        textToEdit: {
            type: Object,
            required: true,
        }
    },
    components: {
        ReUsableTextEditor,
    },

    data() {
        return {
            loading: false,
            blogText: "",
        };
    },

    methods: {
        onOpen(){
            this.blogText = this.textToEdit.htmlContentUrl;
        },

        closeDialog() {
            this.clearInputs();
            this.$emit("close-dialog");
        },

        clearInputs() {
            this.blogText = "";
        },

        validateInput() {
            if (this.blogText === "") {
                return this.showWarningMessage("Text required", "Please provide some text");
            }
            return true;
        },

        async onEdit() {
            if (this.validateInput() !== true) {
                return;
            }

            this.$emit("edit-text", this.blogText);
            this.closeDialog()
        },
    }
}

</script>

<style scoped>
* {
    box-sizing: border-box;
}
</style>