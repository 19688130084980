<template>
  <el-dialog
    :visible="isVisible"
    title="Add Text"
    @close="closeDialog"
    width="80%"
    top="5vh"
  >
    <div v-loading="loading">
      <div class="mb-3">
        <ReUsableTextEditor
          v-model="blogText"
          class="add-blog-reusable-text-editor"
          placeholderText="Please type here"
        ></ReUsableTextEditor>
      </div>
      <div class="d-flex justify-content-end">
        <el-button type="primary" class="" @click="onAdd">Add</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ReUsableTextEditor from "../reusable-text-editor/reusable-text-editor-component.vue";
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ReUsableTextEditor,
  },

  data() {
    return {
      loading: false,
      blogText: "",
    };
  },

  methods: {
    closeDialog() {
      this.clearInputs();
      this.$emit("close-dialog");
    },

    clearInputs() {
      this.blogText = "";
    },

    validateInput() {
      if (this.blogText === "") {
        return this.showWarningMessage("No Content", "Please add some content");
      }
      return true;
    },

    async onAdd() {
      if (this.validateInput() !== true) {
        return;
      }
      const textId = uuidv4();

      const text = {
        id: textId,
        htmlContentUrl: this.blogText,
      };

      this.$emit("add-text", text);
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
</style>

<style>
.add-blog-reusable-text-editor .place-holder {
  position: relative !important;
  top: 48px !important;
}
</style>
